import React from 'react';

import { ReactComponent as PhoneIcon } from '../../assets/icons/Phone.svg';
import { ReactComponent as MailIcon } from '../../assets/icons/Mail.svg';
import { ReactComponent as LocationIcon } from '../../assets/icons/Location.svg';
import { ReactComponent as TimeIcon } from '../../assets/icons/Time.svg';
import { ReactComponent as WhatsAppIcon } from '../../assets/icons/WhatsApp.svg';
import { ReactComponent as TelegramIcon } from '../../assets/icons/Telegram.svg';
import { ReactComponent as MetroIcon } from '../../assets/icons/Metro.svg';
// import { ReactComponent as BusIcon } from '../../assets/icons/Bus.svg';
import './Contacts.scss';
import useBreakpoint from 'use-breakpoint';
import { BREAKPOINTS } from '../../constants/breakpoints';

export const Contacts = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  return (
    <div className='contacts-page'>
      <div className='content-section'>
        <h1>Контакты сервисного центра &quot;FMA Service&quot;</h1>
      </div>
      <div className='content-section'>
        <div className='contacts-blocks'>
          <div className='left-column'>
            <div className='contacts-blocks-items'>
              <a href='tel:+74951779897' className='contact-link'>
                <PhoneIcon /> +7 (495) 177-98-97
              </a>
              <a href='https://wa.me/79152471415' className='contact-link'>
                <WhatsAppIcon /> +7 (915) 247-14-15
              </a>
              <a href='https://t.me/+79152471415' className='contact-link'>
                <TelegramIcon /> +7 (915) 247-14-15
              </a>
              <a href='mailto:i@ffixmyapple.ru' className='contact-link'>
                <MailIcon /> i@ffixmyapple.ru
              </a>
            </div>
            <div className='contacts-blocks-items'>
              <div className='address-block'>
                <LocationIcon />
                <div className='address-block-content'>
                  <p>Москва, м. Студенческая,</p>
                  <p>ул. Киевская 20</p>
                </div>
              </div>
              <div className='schedule-block'>
                <TimeIcon />
                <div className='schedule-block-content'>
                  <p>Пн-Пт с 10:00 до 20:00</p>
                  <p>Сб с 10:00 до 19:00</p>
                  <p>Вс выходной</p>
                </div>
              </div>
            </div>
          </div>
          <div className='right-column'>
            <div className='contacts-blocks-items'>
              <h3>Ближайшие станции метро и остановки:</h3>
              <div className='transport'>
                <div className='transport-column'>
                  <p>
                    <MetroIcon /> Студенческая 130 м
                  </p>
                  <p>
                      <MetroIcon /> Кутузовская 1 км
                  </p>
                </div>
                {/* <div className='transport-column'> */}
                {/*  <p> */}
                {/*    <BusIcon /> Парк Победы 1 км */}
                {/*  </p> */}
                {/*  <p> */}
                {/*    <BusIcon /> Фили 900 м */}
                {/*  </p> */}
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className='contacts-map'>
          {breakpoint === 'mobile' ? (
            <iframe
              src='https://yandex.ru/map-widget/v1/?um=constructor%3A3dd7e6ede4a76aa14a0f9ba8993b17c4c4c6820420d9480411bcaee97cd2d9e3&amp;source=constructor&scroll=false'
              width='100%'
              height='300'
              frameBorder='0'
              style={{ position: 'absolute', left: 0, top: 0 }}
            />
          ) : (
            <iframe
              src='https://yandex.ru/map-widget/v1/?um=constructor%3A3dd7e6ede4a76aa14a0f9ba8993b17c4c4c6820420d9480411bcaee97cd2d9e3&amp;source=constructor&scroll=false'
              width='100%'
              height='600'
              frameBorder='0'
              style={{ position: 'absolute', left: 0, top: 0 }}
            />
          )}
        </div>
      </div>
      {/* <div className='content-section'> */}
      {/*  <h1>Как добраться до нашего сервиса</h1> */}
      {/* </div> */}
      {/* <div className='content-section'> */}
      {/*  <div className='video-responsive'> */}
      {/*    <iframe */}
      {/*      width='853' */}
      {/*      height='480' */}
      {/*      src={`https://www.youtube.com/embed/nVRmhSA6HD4`} */}
      {/*      frameBorder='0' */}
      {/*      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' */}
      {/*      allowFullScreen */}
      {/*      title='Embedded youtube' */}
      {/*    /> */}
      {/*  </div> */}
      {/* </div> */}
    </div>
  );
};
